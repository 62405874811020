<template>
  <pill-base v-on="$listeners">
    <img :src="img" v-if="img" />
  </pill-base>
</template>

<script>
import PillBase from "./PillBase.vue"

export default {
  components: {
    PillBase
  },
  props: {
    data: {
      type: Object,
      validator: (data) => !!data.img
    }
  },
  computed: {
    img() {
      return require(`../assets/calibration/${this.data.img}`)
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  height: 100%;
  width: 100%;
  image-rendering: crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}
</style>
