<template>
  <Page>
    <template>
      <title-link :show-link="false" />

      <h3 class="text-center text-md-left">About Us</h3>

      <div class="d-md-flex align-items-center mt-5">
        <figure class="p-3">
          <img
            class="img-fluid"
            src="../assets/site/about-mat.jpg"
            alt="A digital art portrait of Mat Thorne"
          />
        </figure>
        <div class="px-3 px-lg-5">
          <p>
            <strong>Mat Thorne</strong> is an artist and designer from West
            Virginia. He&rsquo;s also the author of
            <a
              href="https://www.amazon.com/Dial-M-Mutants-Mat-Thorne/dp/1736168207"
              >Dial M for Mutants</a
            >
            and the creator of
            <a href="https://www.instagram.com/midnightextra/"
              >The Midnight Extra</a
            >.
          </p>
          <p><a href="https://mat-thorne.com">mat-thorne.com</a></p>
        </div>
      </div>
      <div class="d-md-flex align-items-center mt-5">
        <figure class="p-3">
          <img
            class="img-fluid"
            src="../assets/site/about-matt.jpg"
            alt="A portrait of Matt Bagwell smiling"
          />
        </figure>
        <div class="px-3 px-lg-5">
          <p>
            <strong>Matt Bagwell</strong> is a frontend developer who rides
            bicycles, overwaters houseplants, watches a lot of basketball, and
            takes the
            <a href="https://www.instagram.com/sweet.potatoes/"
              >occasional photograph</a
            >.
          </p>
          <p><a href="https://www.mattbagwell.com">mattbagwell.com</a></p>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue"
import TitleLink from "@/components/TitleLink.vue"

export default {
  components: {
    Page,
    TitleLink
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@include media-breakpoint-up(md) {
  figure {
    max-width: 25%;
  }
}

figure {
  text-align: center;
}
</style>
