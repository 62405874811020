<template>
  <Page>
    <template>
      <title-link :show-link="false" />

      <hero-pill />

      <section class="full-width -bg-full -bg-gray -no-pad">
        <router-link :to="{ name: 'pills' }">
          <img
            class="grid-img contrast-img"
            srcset="
              ../assets/site/pill-grid-8x3-540w.jpg   540w,
              ../assets/site/pill-grid-8x3-960w.jpg   960w,
              ../assets/site/pill-grid-8x3-1440w.jpg 1440w,
              ../assets/site/pill-grid-8x3-1920w.jpg 1920w
            "
            src="../assets/site/pill-grid-8x3-1920w.jpg"
            sizes="(max-width: 540px) 540px, (max-width: 960px) 960px, (max-width: 1440px) 1440px, 1920px"
            alt="Grid of colorful pixelated digital medicine pills with varying patterns and colors"
          />
        </router-link>
      </section>

      <section class="d-flex flex-column flex-lg-row align-items-center">
        <figure class="img-column fb-40 px-5 pl-md-0">
          <img
            class="img-fluid px-5 pl-lg-0"
            src="../assets/site/blank-pill.jpg"
            width="439"
            height="236"
            alt="An empty pixelated digital medicine pill with a gray background"
          />
        </figure>
        <div class="text-column fb-60 px-4 pt-5 p-lg-0">
          <span class="h4">Dawnville Daily Pills</span> are an NFT collaboration
          between a team of artists, designers, and programmers. Each pill is
          generated by the sentiment of daily digital stressors and designed as
          a virtual pharmaceutical for the day.
        </div>
      </section>

      <section class="-bg-full -bg-gray-light pb-0">
        <figure class="img-column fb-40">
          <figcaption>Fig. 1 Intended Use</figcaption>
          <img
            class="img-fluid"
            srcset="
              ../assets/site/development/equilibrium-960w.jpg 960w,
              ../assets/site/development/equilibrium-540w.jpg 540w
            "
            src="../assets/site/development/equilibrium-960w.jpg"
            sizes="(max-width: 540px) 540px, 960px"
            width="960"
            height="2394"
            alt="An infographic showing how Dawnville Daily Pills create an antidote for the digital stressors in your life."
          />
        </figure>
      </section>

      <section class="d-flex flex-column flex-lg-row">
        <div class="text-column fb-60 px-4 pr-lg-5 pt-5 pt-lg-0">
          <h3 class="pt-3">How It Works</h3>
          <p>
            Each day, our algorithm pulls live data from four targeted areas of
            digital stress: news media, popular culture, financial markets, and
            weather forecasts.
          </p>
          <p>
            Sentiment analysis scores are generated for each subcategory and
            mapped to a color-emotion spectrum before being inverted to form
            specific antiphase values which are then applied to the newly-minted
            NFT daily pill (Fig. 2).
          </p>
        </div>
        <figure class="img-column fb-40 pl-lg-1">
          <figcaption>Fig. 2 Pill Generation</figcaption>
          <img
            class="img-fluid"
            src="../assets/site/development/spectrum.jpg"
            width="480"
            height="790"
            alt="A color spectrum, with points for Popular Culture, News Media, Financial Markets, and Weather, showing how these colors are used to generate a daily digital pill."
          />
        </figure>
      </section>

      <section
        class="d-flex flex-column flex-lg-row -bg-full -bg-gray-light py-5"
      >
        <figure class="img-column fb-50 pr-lg-5">
          <figcaption>Fig. 3 Desired Effects</figcaption>
          <img
            class="img-fluid px-4 px-lg-0 contrast-img"
            srcset="
              ../assets/site/development/brain-960w.jpg 960w,
              ../assets/site/development/brain-540w.jpg 540w
            "
            src="../assets/site/development/brain-960w.jpg"
            sizes="(max-width: 540px) 540px, (max-width: 960px) 960px, 540px"
            width="960"
            height="699"
            alt="A digital pill being fed to a cartoon brain"
          />
        </figure>
        <div class="text-column fb-50 px-4 pt-5 pt-lg-0 align-items-center">
          <h3 class="pt-3">Minting</h3>
          <p>
            New NFT pills are minted once a day, beginning on January 1, 2022.
            Each pill is given a name, unique characteristics, and a permanent
            home on the Ethereum blockchain where it can be purchased,
            collected, traded, and utilized for digital tranquility (Fig. 3).
          </p>
        </div>
      </section>

      <section>
        <h3 class="browse-text h2" v-if="pills.length">Browse the Pharmacy</h3>
        <h4
          v-if="pills.length"
          class="mt-5 mb-3 font-weight-bold text-center text-lg-left"
        >
          Recently Issued Pills
        </h4>
        <b-spinner
          v-if="loading"
          type="grow"
          large
          label="Loading pills..."
          class="d-block mx-auto"
        />
        <pill-grid :pills="pills" :cols="5" />
        <h4 class="text-center mt-5 pt-4 font-weight-bold">
          <router-link :to="{ name: 'pills' }"
            >View the Full Collection</router-link
          >
        </h4>
        <h4 class="text-center mt-5 font-weight-bold">
          Purchase on
          <a href="https://opensea.io/collection/dawnville-pharmacy">OpenSea</a>
        </h4>
      </section>
    </template>
  </Page>
</template>

<script>
import { subDays } from "date-fns"
import api from "@/utils/api"
import { dateToDashes } from "@/utils/date"

import Page from "@/components/Page.vue"
import HeroPill from "@/components/HeroPill.vue"
import PillGrid from "@/components/PillGrid.vue"
import TitleLink from "@/components/TitleLink.vue"

export default {
  components: {
    HeroPill,
    Page,
    PillGrid,
    TitleLink
  },
  data() {
    return {
      loading: true,
      pills: []
    }
  },
  async created() {
    const opts = {
      startDate: dateToDashes(subDays(new Date(), 5)),
      endDate: dateToDashes()
    }
    const data = await api.callFunction("pill-data", opts)
    this.loading = false
    this.pills = data.slice(-5).reverse()
  }
}
</script>

<style lang="scss" scoped>
.fb-40 {
  flex-basis: 40%;
}

.fb-50 {
  flex-basis: 50%;
}

.fb-60 {
  flex-basis: 60%;
}

h1,
h2,
h3 {
  font-weight: 800;
}

section {
  margin-top: calc(var(--section-v-spacing) / 2);
  margin-bottom: calc(var(--section-v-spacing) / 2);
  padding-top: calc(var(--section-v-spacing) / 2);
  padding-bottom: calc(var(--section-v-spacing) / 2);

  &.-no-pad {
    padding-top: 0;
    padding-bottom: 0;
  }
}

// When images stack on smaller viewports, display text on top
@include media-breakpoint-down(md) {
  .text-column {
    order: 1;
  }
}

.text-column {
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;

  h3 {
    margin-bottom: 1.5rem;
  }
}

.img-column {
  flex-grow: 1;
  flex-shrink: 0;
  text-align: center;
  margin-bottom: 0;
}

.grid-img {
  width: 100%;
  height: auto;
}

.contrast-img {
  image-rendering: crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

figcaption {
  font-family: var(--font-serif);
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 2.5rem;
}

.browse-text {
  font-size: calc(var(--title-font-size) * 2);
  font-weight: bold;
  font-family: var(--font-sans-serif);
  letter-spacing: -0.025em;
  text-align: center;
  margin-bottom: calc(var(--title-font-size) * 3);
}
</style>
