<template>
  <Page>
    <template>
      <title-link :show-link="false" />

      <b-form
        name="contact"
        class="contact-form mx-auto px-5 pb-5"
        @submit.prevent="submitForm"
      >
        <h2 class="my-5 pt-5">Contact Us</h2>

        <input type="hidden" name="form-name" value="contact" />

        <b-form-group label="Name" label-for="input-name">
          <b-form-input
            v-model="form.name"
            id="input-name"
            name="name"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Email" label-for="input-email">
          <b-form-input
            v-model="form.email"
            id="input-email"
            type="email"
            name="email"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Question or comment" label-for="input-comment">
          <b-form-textarea v-model="form.comment" name="comment" />
        </b-form-group>
        <b-alert
          class="mt-5"
          :variant="alertType"
          :show="alert.length > 0"
          dismissible
        >
          {{ alert }}
        </b-alert>
        <b-button
          type="submit"
          class="d-block mx-auto mt-5"
          :disabled="!isFormComplete"
          >Submit</b-button
        >
      </b-form>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue"
import TitleLink from "@/components/TitleLink.vue"
import axios from "axios"

export default {
  components: {
    Page,
    TitleLink
  },
  data() {
    return {
      alert: "",
      alertType: null,
      form: {
        name: "",
        email: "",
        comment: ""
      }
    }
  },
  computed: {
    isFormComplete() {
      return this.form.name && this.form.email && this.form.comment
    }
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&")
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        comment: ""
      }
    },
    async submitForm() {
      const config = {
        header: { "Content-Type": "application/x-www-form-urlencoded" }
      }
      const data = this.encode({
        "form-name": "contact",
        ...this.form
      })
      try {
        await axios.post("/", data, config)
        this.alert = "Message sent successfully. Thanks!"
        this.alertType = "success"
        this.resetForm()
      } catch (err) {
        this.alert = "An error ocurred. Please try again later."
        this.alertType = "danger"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@include media-breakpoint-up(md) {
  .contact-form {
    width: 75%;
  }
}
</style>
