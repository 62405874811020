<template>
  <div class="banner-container full-width" ref="banner">
    <div class="img-wrapper front" :style="getMaskStyle"></div>
    <div class="img-wrapper back"></div>
  </div>
</template>

<script>
let bannerTop = 0,
  bannerHeight = 0,
  observer = null,
  scrollDistance = 0

export default {
  data() {
    return {
      maskPos: 0
    }
  },
  mounted() {
    const opts = {
      rootMargin: "0px",
      threshold: 1.0
    }
    observer = new IntersectionObserver(this.updateListener, opts)
    observer.observe(this.$refs.banner)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollListener)
  },
  computed: {
    getMaskStyle() {
      const start = this.maskPos
      const end = this.maskPos * 1.5
      const str = `linear-gradient(to bottom, transparent ${start}%, black ${end}%, black 200%)`
      return {
        maskImage: str,
        webkitMaskImage: str
      }
    }
  },
  methods: {
    calcBannerDims() {
      bannerHeight = this.$refs.banner.clientHeight
      bannerTop = this.$refs.banner.offsetTop
      scrollDistance = window.innerHeight - bannerHeight
    },
    updateListener(targets) {
      // TODO: update so banner dimensions are recalculated once content
      // above it (i.e. imgs) has re-rendered to final dimensions
      if (targets[0].isIntersecting) {
        // Get banner geometry, in case window has been resized
        this.calcBannerDims()

        // Prevent duplicate listeners if user scrolls over this image repeatedly
        window.document.removeEventListener("scroll", this.scrollListener)
        window.document.addEventListener("scroll", this.scrollListener)
      }
    },
    scrollListener() {
      // 0 - when bottom edge of banner is on bottom edge of screen
      // 1 - when top edge of banner is on top edge of screen
      let revealPercent = 1 - (bannerTop - window.scrollY) / scrollDistance
      revealPercent =
        revealPercent < 0
          ? 0
          : revealPercent > 1
          ? 100
          : revealPercent.toPrecision(2) * 100
      this.maskPos = revealPercent
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-container {
  --pill-width: 80vw;

  display: grid;
  width: var(--pill-width);
  height: calc(var(--pill-width) * 0.3); // preserve aspect ratio
  max-height: 360px; // don't let it get huge on large screens
  margin-top: 5rem;
  margin-bottom: 7rem;

  .img-wrapper {
    grid-column: 1;
    grid-row: 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity 0.1s;
  }
}

.img-wrapper.front {
  background-image: url("../assets/site/code-pill-540w.jpg");
  mask-image: linear-gradient(to bottom, transparent 0, black 0%, black 200%);
}

.img-wrapper.back {
  background-image: url("../assets/site/color-pill-980w.png");
}

@include media-breakpoint-up(sm) {
  .img-wrapper.front {
    background-image: url("../assets/site/code-pill-980w.jpg");
  }
}
</style>
