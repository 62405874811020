import axios from "axios"

const base = `/api`

// Basic caching to bypass repeated API calls
const cacheObj: Record<string, unknown> = {}

const callFunction = async (endpoint: string, data = {}) => {
  // combine endpoint+data combination into a unique key
  const objAsParams = new URLSearchParams(Object.entries(data))
  const key = `${endpoint.toLowerCase()}-${objAsParams}`

  if (cacheObj[key]) {
    return cacheObj[key]
  } else {
    const response = await axios.post(`${base}/${endpoint}`, data)
    cacheObj[key] = response.data
    return response.data
  }
}

export default {
  callFunction
}
