import Vue from "vue"
import BootstrapVue from "bootstrap-vue"
import App from "./App.vue"
import router from "./router"

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

Vue.use(BootstrapVue)

Vue.config.productionTip = false

const app = new Vue({
  router,
  el: "#app",
  template: "<App />",
  components: { App }
})
