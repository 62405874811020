<template>
  <section class="footer -bg-full -bg-gray-light">
    <div>
      <router-link :to="{ name: 'home' }">Home</router-link>
      <span class="mx-4">|</span>
      <router-link :to="{ name: 'pills' }">Collection</router-link>
      <span class="mx-4">|</span>
      <router-link :to="{ name: 'about' }">About</router-link>
      <span class="mx-4">|</span>
      <router-link :to="{ name: 'contact' }">Contact</router-link>
    </div>
  </section>
</template>

<script>
export default {
  components: {}
}
</script>

<style lang="scss" scoped>
.footer {
  height: 100px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
}
</style>
