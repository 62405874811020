import Vue from "vue"
import VueRouter from "vue-router"

import Detail from "./views/Detail.vue"
import DetailCalibration from "./views/DetailCalibration.vue"
import PharmacyPage from "./views/Pharmacy.vue"
import HomePage from "./views/Home.vue"
import ContactPage from "./views/Contact.vue"
import AboutPage from "./views/About.vue"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: HomePage
    },
    {
      /// matches daily pill routes
      path: "/pill/:date(\\d{2}-\\d{2}-\\d{4})",
      name: "detail-daily",
      component: Detail
    },
    {
      // matches calibration pill routes
      path: "/pill/:id(\\d{3})",
      name: "detail-calibration",
      component: DetailCalibration
    },
    {
      path: "/pills",
      name: "pills",
      component: PharmacyPage
    },
    {
      path: "/contact",
      name: "contact",
      component: ContactPage
    },
    {
      path: "/about",
      name: "about",
      component: AboutPage
    }
  ]
})

export default router
