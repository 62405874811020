<template>
  <div class="pills-container" :class="`-cols-${cols}`">
    <div class="pill-wrapper" v-for="pill in pills" :key="getKey(pill)">
      <div v-if="!pill.calibration">
        <pill class="mb-1" :data="pill" @click="goToDetail(pill)" />
        {{ formatDate(pill.created_at) }}
      </div>
      <div v-else>
        <pill-calibration
          class="mb-1"
          :data="pill"
          @click="goToCalibration(pill)"
        />
        {{ pill.title }}
      </div>
    </div>
  </div>
</template>

<script>
import Pill from "./Pill.vue"
import PillCalibration from "./PillCalibration.vue"
import { monthNames } from "@/utils/date"

export default {
  components: {
    Pill,
    PillCalibration
  },
  props: {
    pills: {
      type: Array
    },
    cols: {
      type: Number,
      default: 5,
      validator(num) {
        return num == 5 || num == 7
      }
    }
  },
  methods: {
    formatDate(created) {
      const [month, date] = created.slice(0, 5).split("/")
      return monthNames[String(Number(month)) - 1] + " " + Number(date)
    },
    getKey(pill) {
      return pill.isCalibration ? pill.id : pill.created_at
    },
    goToDetail(pill) {
      const date = pill.created_at.replace(/\//g, "-")
      this.$router.push({ name: "detail-daily", params: { date } })
    },
    goToCalibration(pill) {
      this.$router.push({ name: "detail-calibration", params: { id: pill.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.pills-container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

::v-deep .graphic {
  width: 40vw;
  height: 40vw;
}

.pill-wrapper {
  cursor: pointer;
  text-align: center;
  font-size: 0.8125rem;
  margin: 0 auto;
}

@include media-breakpoint-up(md) {
  .pills-container {
    grid-template-columns: repeat(4, 1fr);
  }
  ::v-deep .graphic {
    width: 175px;
    height: 175px;
  }
}

@include media-breakpoint-up(lg) {
  .pills-container {
    &.-cols-5 {
      grid-template-columns: repeat(5, 1fr);
      ::v-deep .graphic {
        width: 180px;
        height: 180px;
      }
    }
    &.-cols-7 {
      grid-template-columns: repeat(7, 1fr);
      ::v-deep .graphic {
        width: 125px;
        height: 125px;
      }
    }
  }
}
</style>
