import { SourceType } from "./types/source"

export const sourceTypes: SourceType[] = ["news", "music", "finance", "weather"]

const NAME_PART_1 = [
  "Flu",
  "Par",
  "Bu",
  "Mir",
  "Phen",
  "Val",
  "Tri",
  "Bi",
  "Per",
  "Thior",
  "Ris",
  "Lor",
  "Zot",
  "Pal",
  "Clo",
  "Ven",
  "Diaz",
  "Tem",
  "Zop",
  "Lam"
]

const NAME_PART_2 = [
  "dex",
  "tro",
  "meth",
  "phen",
  "xam",
  "amph",
  "diaz",
  "zop",
  "azo",
  "zod",
  "zol",
  "per",
  "pro",
  "pen",
  "pra",
  "hal",
  "zip",
  "ris",
  "zap",
  "zal"
]

const NAME_PART_3 = [
  "ox",
  "az",
  "el",
  "en",
  "id",
  "ip",
  "al",
  "er",
  "ix",
  "ex",
  "er",
  "il",
  "op",
  "al",
  "iz",
  "ax",
  "ot",
  "it",
  "at",
  "et"
]

const NAME_PART_4 = [
  "apine",
  "idol",
  "ixene",
  "azine",
  "olam",
  "oxide",
  "epam",
  "iclone",
  "eplon",
  "idem",
  "ium",
  "epine",
  "igine",
  "idone",
  "epine",
  "igine",
  "etine",
  "elzine",
  "omine",
  "aline"
]

const INGREDIENT_PART_1 = [
  "ev",
  "trib",
  "cab",
  "ver",
  "umb",
  "for",
  "das",
  "vil",
  "dor",
  "dob",
  "am",
  "in",
  "ib",
  "fin",
  "fex",
  "av",
  "mob",
  "mar",
  "paf",
  "od"
]

const INGREDIENT_PART_2 = [
  "olic",
  "ilud",
  "uler",
  "abil",
  "ubiz",
  "idut",
  "erul",
  "unik",
  "aliv",
  "acor",
  "adit",
  "elif",
  "icel",
  "umib",
  "ekiz",
  "arid",
  "irol",
  "inter",
  "avin",
  "urid"
]

const INGREDIENT_PART_3 = [
  "oraz",
  "inter",
  "ilver",
  "numar",
  "daz",
  "paz",
  "ilic",
  "vet",
  "gluc",
  "tor",
  "per",
  "par",
  "limba",
  "evix",
  "glucos",
  "trop",
  "grav",
  "bral",
  "lox",
  "cop"
]

const INGREDIENT_PART_4 = [
  "ide",
  "itide",
  "feron",
  "opan",
  "ibat",
  "epant",
  "otin",
  "inib",
  "opin",
  "alin",
  "ifan",
  "idase",
  "umab",
  "ibat",
  "udil",
  "azole",
  "inase",
  "oplan",
  "amide",
  "erin"
]

const INGREDIENT_SUFFIX_1 = [
  "-if",
  "-iz",
  "-hn",
  "-gx",
  "-fz",
  "-te",
  "-iz",
  "-hd",
  "-ns",
  "-sv",
  "-tc",
  "-tf"
]

const INGREDIENT_SUFFIX_2 = [
  "yl",
  "ac",
  "ng",
  "xy",
  "rl",
  "yd",
  "vi",
  "ua",
  "ft",
  "gd",
  "nj",
  "tv"
]

export const DAY_AVG = [
  ["Lovely", "Peaceful", "Excellent", "Aces", "Splendid"],
  ["Alright", "Nice", "Pleasant", "Pretty Good", "Trouble-free"],
  ["Kinda Gross", "Meh", "Not the Worst", "Middle of the Road", "So-so"],
  ["Icky", "Yucko", "Lousy", "No Thanks", "Unacceptable"],
  ["Dreadful", "Panic Inducing", "Painful", "Horrid", "Alarming"]
]

export const SENTIMENT = [
  ["Quite Pleasant", "Blissful", "Rather Nice", "Tranquil", "Ideal"],
  ["Good Enough", "Fine", "Decent", "Calm", "Not Bad"],
  ["Mildly Stressful", "Just OK", "Not Great", "Middling"],
  ["Difficult", "Trying", "Challenging", "Worrisome", "Unsettling", "Daunting"],
  ["Anxiety Producing", "Depressing", "Go Back to Sleep", "Awful"]
]

export const EFFECT = [
  ["Sugar Pill", "Placebo", "Candy Pill", "Confection"],
  ["Mood Stabilizer", "Mild Sedative", "Stimulant", "Mild Tranquilizer"],
  ["Tranquilizer", "Sedative", "Mild Hypnotic", "Barbiturate"],
  ["Sedative", "Mild Anxiolytic", "Hypnotic", "Amnesic"],
  ["Antidepressant", "Anxiolytic", "Sadness Inhibitor", "Major Tranquilizer"]
]

export const NAME = [NAME_PART_1, NAME_PART_2, NAME_PART_3, NAME_PART_4]

export const INGREDIENT = [
  INGREDIENT_PART_1,
  INGREDIENT_PART_2,
  INGREDIENT_PART_3,
  INGREDIENT_PART_4,
  INGREDIENT_SUFFIX_1,
  INGREDIENT_SUFFIX_2
]

export const CALIBRATION_PILLS = [
  {
    id: 1,
    calibration: true,
    img: "calibration-001.png",
    title: "Calibration Pill 001",
    url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55789458214096780950292796380378801700810783175489536161543708089091501326337"
  },
  {
    id: 2,
    calibration: true,
    img: "defect-001.png",
    title: "Factory Defect 001",
    url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55789458214096780950292796380378801700810783175489536161543708101186129231873"
  },
  {
    id: 3,
    calibration: true,
    img: "defect-002.png",
    title: "Factory Defect 002",
    url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55789458214096780950292796380378801700810783175489536161543708118778315276289"
  },
  {
    id: 4,
    calibration: true,
    img: "defect-003.png",
    title: "Factory Defect 003",
    url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55789458214096780950292796380378801700810783175489536161543708118778315276289"
  }
]
