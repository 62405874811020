import { utcToZonedTime } from "date-fns-tz"

/*
  Returns the current date in the format 2022-01-01
  If `useNYTime` is true, formats in East Coast timezone, otherwises
  will format in UTC time
*/
export const dateToDashes = (srcDate = new Date(), useNYTime = true) => {
  let year, month, date
  if (useNYTime) {
    srcDate = utcToZonedTime(srcDate, "America/New_York")
    year = srcDate.getFullYear()
    month = srcDate.getMonth() + 1
    date = srcDate.getDate()
  } else {
    year = srcDate.getUTCFullYear()
    month = srcDate.getUTCMonth() + 1
    date = srcDate.getUTCDate()
  }

  month = String(month).padStart(2, "0")
  date = String(date).padStart(2, "0")
  return `${year}-${month}-${date}`
}

// Convert Date instance to the format mm/dd/yyyy
export const dateToSlashes = (dateObj: Date) => {
  const year = dateObj.getUTCFullYear()
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0")
  const date = String(dateObj.getUTCDate()).padStart(2, "0")
  return `${month}/${date}/${year}`
}

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]
