<template>
  <div
    class="
      links
      full-width
      py-3
      px-5
      d-flex
      align-items-center
      flex-column flex-md-row
      justify-content-around
    "
  >
    <div class="my-1 mb-3 my-md-0 home-link">
      <router-link :to="{ name: 'home' }">
        <img
          src="../assets/site/logo-sm.png"
          width="32"
          class="pb-1"
          alt="The Dawnville Pharmacy logo, a pixelated white cross on an orange background."
        />
        Dawnville Pharmacy</router-link
      >
    </div>
    <div class="my-3 my-md-0 px-4 px-lg-5 ml-md-auto">
      <a href="https://opensea.io/collection/dawnville-pharmacy"
        >Available on OpenSea</a
      >
    </div>
    <div class="my-1 my-md-0 px-4 px-lg-5">
      <router-link :to="{ name: 'pills' }">View Collection</router-link>
    </div>
    <div class="my-3 my-md-0 px-4 px-lg-5">
      <router-link :to="{ name: 'about' }">About</router-link>
    </div>
    <div class="my-3 my-md-0 pl-md-4 pl-lg-5">
      <router-link :to="{ name: 'contact' }">Contact</router-link>
    </div>
  </div>
</template>

<script>
export default {
  components: {}
}
</script>

<style lang="scss" scoped>
.links {
  text-transform: uppercase;
  text-align: center;

  .home-link a {
    text-transform: none;
    text-decoration: none;
  }
}

a {
  text-decoration: none;
}

.home-link {
  width: 230px;
  text-align: left;

  a {
    font-family: var(--font-sans-serif);
    font-weight: bold;
    color: inherit;
  }
}
</style>
