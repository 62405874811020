<template>
  <section class="pill-raw-content p-5 -bg-full -bg-black">
    <div class="content" v-if="loaded">
      <h3 class="mb-4">Daily Transmitter Sampling</h3>
      <div class="snippet">
        <span class="title">News Media Data Excerpt</span> //
        {{ content.news }} //
        <strong>Sentiment Input:</strong>
        <span class="swatch" :style="swatchStyle(colors.leftHSL, true)"></span>
        <strong>Anti-Phase Output:</strong>
        <span class="swatch" :style="swatchStyle(colors.leftHSL)"></span>
      </div>
      <div class="snippet">
        <span class="title">Financial Volatility Data</span> //
        {{ content.finance }} //
        <strong>Sentiment Input:</strong>
        <span class="swatch" :style="swatchStyle(colors.rightHSL, true)"></span>
        <strong>Anti-Phase Output:</strong>
        <span class="swatch" :style="swatchStyle(colors.rightHSL)"></span>
      </div>
      <div class="snippet">
        <span class="title">Popular Culture Data Excerpt</span> //
        {{ content.music }} //
        <strong>Sentiment Input:</strong>
        <span
          class="swatch"
          :style="swatchStyle(colors.leftPatternHSL, true)"
        ></span>
        <strong>Anti-Phase Output:</strong>
        <span class="swatch" :style="swatchStyle(colors.leftPatternHSL)"></span>
      </div>
      <div class="snippet">
        <span class="title">Weather Data Excerpt</span> //
        {{ content.weather }} //
        <strong>Sentiment Input:</strong>
        <span
          class="swatch"
          :style="swatchStyle(colors.rightPatternHSL, true)"
        ></span>
        <strong>Anti-Phase Output:</strong>
        <span
          class="swatch"
          :style="swatchStyle(colors.rightPatternHSL)"
        ></span>
      </div>
    </div>
    <b-spinner
      type="grow"
      large
      label="Loading today's data..."
      class="d-block mx-auto"
      v-if="!loaded"
    />
  </section>
</template>

<script>
import { isValid as isValidDate, parse } from "date-fns"
import api from "@/utils/api"
import { isHSLObj } from "@/utils/validators"
import { sourceTypes } from "@/constants"

export default {
  props: {
    date: {
      type: String,
      required: true,
      validator: (val) => {
        return isValidDate(parse(val, "MM-dd-yyyy", new Date()))
      }
    },
    colors: {
      type: Object,
      required: true,
      validator: (val) => {
        return (
          isHSLObj(val.leftHSL) &&
          isHSLObj(val.rightHSL) &&
          isHSLObj(val.leftPatternHSL) &&
          isHSLObj(val.rightPatternHSL)
        )
      }
    }
  },
  data() {
    return {
      content: Object.fromEntries(sourceTypes.map((type) => [type, ""])),
      loaded: false
    }
  },
  async created() {
    const opts = {
      date: this.date
    }
    try {
      const data = await api.callFunction("pill-raw-content", opts)
      this.content = data
      this.loaded = true
    } catch (err) {
      console.warn("Error fetching pill sample content")
    }
  },
  computed: {
    // formattedDate() {
    //   const dateObj = parse(this.date, "yyyy-mm-dd", new Date())
    //   return format(dateObj, "yyyy-MM-dd")
    // }
  },
  methods: {
    swatchStyle(HSL, isSentiment = false) {
      let { h, s, l } = HSL
      h = isSentiment ? h - 180 : h
      return `background-color: hsl(${h}, ${s}%, ${l}%);`
    }
  }
}
</script>

<style lang="scss" scoped>
.pill-raw-content {
  color: white;
}

.snippet {
  margin-top: 1rem;
  font-family: "Courier New", Courier, monospace;
  word-break: break-all;
}

.title {
  font-weight: bold;
}

.swatch {
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  margin: 0 1.5rem 0 0.25rem;
  vertical-align: middle;
  line-height: 1;
}
</style>
