<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {}
}
</script>

<style lang="scss">
html {
  --color-blue-light: rgb(37, 214, 234);
  --color-gray: rgb(102, 102, 102);
  --color-orange: #f29c3c;
  --font-sans-serif: Helvetica, Avenir, Arial, sans-serif;
  --font-serif: "Times New Roman", serif;
  --title-font-size: 16px;
  --section-v-spacing: 5rem;
}

@include media-breakpoint-up(md) {
  html {
    --title-font-size: 20px;
    --section-v-spacing: 8rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    --title-font-size: 24px;
    --section-v-spacing: 10rem;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

.full-width {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 100vw;
}

.-bg-full {
  position: relative;

  &::before {
    content: "";
    width: 100vw;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  &.-bg-black:before {
    background-color: #000000;
  }

  &.-bg-gray::before {
    background-color: #e9e9e9;
  }

  &.-bg-gray-light::before {
    background-color: #f2f2f2;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px 10px 0;
}
</style>
