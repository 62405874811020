<template>
  <pill-base v-on="$listeners">
    <div class="pill-bg pill-bg-left" :style="lBgStyle"></div>
    <div class="pill-bg pill-bg-right" :style="rBgStyle"></div>
    <div class="graphic pattern-left" :style="getPatternStyle('left')"></div>
    <div class="graphic pattern-right" :style="getPatternStyle('right')"></div>
    <div class="graphic texture"></div>
  </pill-base>
</template>

<script>
import { isHSLObj, isValidPattern } from "@/utils/validators"
import PillBase from "./PillBase.vue"

export default {
  components: {
    PillBase
  },
  props: {
    data: {
      type: Object,
      validator: (data) => {
        // Verify we have all the colors and patterns needed to render a pill
        const graphic = data.graphic
        return (
          isHSLObj(graphic.leftHSL) &&
          isHSLObj(graphic.rightHSL) &&
          isHSLObj(graphic.leftPatternHSL) &&
          isHSLObj(graphic.rightPatternHSL) &&
          isValidPattern(graphic.leftPatternNum) &&
          isValidPattern(graphic.rightPatternNum)
        )
      }
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lBgStyle() {
      const { h, s, l } = this.data.graphic.leftHSL
      return `background-color: hsl(${h}, ${s}%, ${l}%);`
    },

    rBgStyle() {
      const { h, s, l } = this.data.graphic.rightHSL
      return `background-color: hsl(${h}, ${s}%, ${l}%);`
    },

    getClass() {
      return this.fullWidth ? "-bg-full -bg-gray" : ""
    },
    imageStyle() {
      return this.img ? `background: url(${this.img})` : ""
    }
  },
  methods: {
    getPatternStyle(side) {
      const { h, s, l } =
        side === "left"
          ? this.data.graphic.leftPatternHSL
          : this.data.graphic.rightPatternHSL
      const patNum =
        side === "left"
          ? this.data.graphic.leftPatternNum
          : this.data.graphic.rightPatternNum
      // See https://stackoverflow.com/questions/40491506/vue-js-dynamic-images-not-working
      const url = require(`../assets/pill/pattern-${side}-${patNum}.png`)
      const mask = `url(${url})`
      return `background-color: hsl(${h}, ${s}%, ${l}%); mask-image: ${mask}; `
    }
  }
}
</script>

<style lang="scss" scoped>
.pill-left {
  z-index: 1;
  background: center / 100% no-repeat url(../assets/pill/pill-left.png);
}

.pill-right {
  z-index: 1;
  background: center / 100% no-repeat url(../assets/pill/pill-right.png);
}

.pill-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.pill-bg-left {
  mask-image: url(../assets/pill/pill-left.png);
}

.pill-bg-right {
  mask-image: url(../assets/pill/pill-right.png);
}

// Pattern 1 for each side is blank
.pattern-left {
  mask-image: url(../assets/pill/pattern-left-1.png);
}

.pattern-right {
  mask-image: url(../assets/pill/pattern-right-1.png);
}

// shared mask styles
.pill-bg,
.pattern-left,
.pattern-right {
  mask-position: center;
  mask-size: 100%;
  mask-repeat: no-repeat;
}

.texture {
  background: center / 100% no-repeat url(../assets/pill/pill-texture.png);
  z-index: 10;
}
</style>
