<template>
  <section class="text-center my-3 py-3 my-lg-5 py-lg-5">
    <router-link :to="{ name: 'home' }" v-if="showLink">
      <h1 class="title-text">
        Dawnville Daily Pills<sup
          ><img
            src="../assets/site/tm-black.png"
            class="trademark"
            alt="A trademark logo with the letter D instead of the letters TM"
        /></sup>
      </h1>
    </router-link>
    <h1 class="title-text" v-else>
      Dawnville Daily Pills<sup
        ><img
          src="../assets/site/tm-black.png"
          class="trademark"
          alt="A trademark logo with the letter D instead of the letters TM"
      /></sup>
    </h1>
    <h2 class="subtext h4 mb-4">NFT Pills for Digital Ills</h2>
    <div class="h6">01/01/2022 &mdash; 12/31/2022</div>
    <div class="text-uppercase h6">Issued daily</div>
  </section>
</template>

<script>
export default {
  props: {
    showLink: {
      default: true,
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  font-family: var(--font-sans-serif);
}

.title-text {
  font-size: calc(var(--title-font-size) * 4);
  font-weight: bold;
  letter-spacing: -0.025em;
}

.subtext {
  font-family: var(--font-serif);
  font-size: 1.875rem;
  font-style: italic;
}

a,
.title-text {
  color: black;
  &:hover {
    text-decoration: none;
  }
}

.trademark {
  width: 35px;
  height: 35px;
}
</style>
