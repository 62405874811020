<template>
  <Page>
    <title-link class="mt-5" />

    <div class="details-col">
      <div class="text-center">
        <h5 v-if="errorMsg.length">{{ errorMsg }}</h5>
        <b-spinner
          type="grow"
          large
          label="Loading pills..."
          v-if="!pillLoaded"
        />
      </div>

      <div v-if="pillLoaded && !errorMsg">
        <Pill
          class="pill mt-5 mb-5"
          v-if="pillLoaded"
          :data="pill"
          :full-width="true"
        />
        <h2 class="name mb-0">
          {{ metadata.name
          }}<sup
            ><img
              src="../assets/site/tm.png"
              class="trademark"
              alt="A trademark logo with the letter D instead of the letters TM"
          /></sup>
        </h2>
        <div class="ingredient h5 mb-5">
          ({{ metadata.ingredient }}) Capsule
          <span class="dosage">{{ metadata.dosage }}mg</span>
        </div>

        <div v-if="metadata.url" class="my-5">
          <b-button variant="primary" :href="metadata.url" target="_blank"
            >View on OpenSea.io
          </b-button>
        </div>

        <ul class="details list-inline">
          <li><label>Date:</label>{{ pill.created_at || "Unknown" }}</li>
          <li><label>Name:</label>{{ metadata.name }}</li>
          <li><label>Active Ingredient:</label>{{ metadata.ingredient }}</li>
          <li><label>Strength:</label>{{ metadata.dosage }}MG</li>
          <li><label>Type:</label>Capsule; Oral</li>
          <li><label>Effect:</label>{{ metadata.effect }}</li>
          <li><label>Daily Sentiment:</label>{{ metadata.sentiment }}</li>
        </ul>
      </div>
    </div>
    <pill-raw-content
      v-if="pill.graphic"
      :date="$route.params.date"
      :colors="pill.graphic"
    />
  </Page>
</template>

<script>
import Page from "@/components/Page.vue"
import Pill from "@/components/Pill.vue"
import PillRawContent from "@/components/PillRawContent.vue"
import api from "@/utils/api"
import TitleLink from "@/components/TitleLink.vue"

export default {
  components: {
    Page,
    Pill,
    PillRawContent,
    TitleLink
  },
  data() {
    return {
      effect: "",
      errorMsg: "",
      name: "",
      pill: {},
      pillLoaded: false,
      sentiment: "",
      strength: 0,
      type: ""
    }
  },
  async created() {
    const opts = {
      startDate: this.$route.params.date,
      endDate: this.$route.params.date,
      includeMeta: true
    }
    const data = await api.callFunction("pill-data", opts)
    if (data[0]) {
      this.pill = data[0]
    } else {
      this.errorMsg = `No pill found for ${this.$route.params.date}`
    }
    this.pillLoaded = true
  },
  computed: {
    metadata() {
      return {
        name: "Unknown Pill",
        dosage: "??? ",
        effect: "Unknown",
        ingredient: "Unknown",
        sentiment: "Unknown",
        ...(this.pill?.metadata || {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.details-col {
  max-width: 400px;
  margin: 6rem auto 5rem;
}

.name {
  color: var(--color-blue-light);
  font-weight: bold;
}

.dosage {
  font-size: 1rem;
  border: 1px solid black;
  border-radius: 0.375rem;
  margin-left: 0.5rem;
  padding: 0 0.375rem;
}

.trademark {
  width: 1rem;
  height: auto;
}

.details {
  color: var(--color-gray);

  label {
    font-weight: bold;
    margin-right: 0.5rem;
  }
}

.details li {
  margin-bottom: 0.5rem;
}
</style>
