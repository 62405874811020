const ALLOWED_PROPS = ["h", "s", "l"] as const

export type HSLColor = Record<typeof ALLOWED_PROPS[number], number>

const isHSLObj = (color: HSLColor) => {
  const hasRequiredKeys = ALLOWED_PROPS.every((key) => key in color)
  const noExtraKeys = Object.keys(color).length === 3
  const keysAreNumbers = ALLOWED_PROPS.every(
    (key) => typeof color[key] === "number"
  )

  return hasRequiredKeys && noExtraKeys && keysAreNumbers
}

const isValidPattern = (num: number) => num >= 1 && num <= 19

export { isHSLObj, isValidPattern }
