<template>
  <div class="wrapper" :class="getClass" v-on="$listeners">
    <div class="graphic token">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getClass() {
      return this.fullWidth ? "-bg-full -bg-gray" : ""
    }
  }
}
</script>

<style lang="scss" scoped>
.graphic {
  width: 400px;
  height: 400px;
  position: absolute;
  left: 0;
  top: 0;
}

.token {
  background: rgb(233, 233, 233) center / 100% no-repeat
    url(../assets/pill/shadow.png);
  z-index: 0;
  position: relative;
}
</style>
