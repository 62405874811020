<template>
  <Page>
    <title-link />
    <b-spinner
      v-if="loading"
      type="grow"
      large
      label="Loading pills..."
      class="d-block mx-auto my-5"
    />
    <h3 v-if="pills.length">Daily Pills</h3>
    <pill-grid :pills="pills" class="py-5" />

    <h3 class="mt-5">Calibration Pills &amp; Factory Defects</h3>
    <pill-grid :pills="calibrationPills" class="py-5" />
  </Page>
</template>

<script>
import Page from "@/components/Page.vue"
import PillGrid from "@/components/PillGrid.vue"
import api from "@/utils/api"
import TitleLink from "@/components/TitleLink.vue"
import { CALIBRATION_PILLS } from "@/constants"

export default {
  components: {
    Page,
    PillGrid,
    TitleLink
  },
  data() {
    return {
      loading: true,
      pills: [],
      startDate: "2022-01-01",
      endDate: "2022-12-31",
      calibrationPills: CALIBRATION_PILLS
    }
  },
  async created() {
    const opts = {
      startDate: this.startDate,
      endDate: this.endDate
    }
    const data = await api.callFunction("pill-data", opts)
    this.loading = false
    this.pills = data
  }
}
</script>
<style lang="scss" scoped></style>
