<template>
  <page>
    <title-link class="mt-5" />
    <div class="details-col" v-if="pill">
      <pill-calibration
        class="pill mt-5 mb-5"
        :data="pill"
        :full-width="true"
      />
      <h2 class="name mb-0">
        {{ pill.title }}
      </h2>

      <b-button
        v-if="pill.url"
        variant="primary"
        :href="pill.url"
        target="_blank"
        class="my-5"
        >View on OpenSea.io
      </b-button>
    </div>
  </page>
</template>

<script>
import Page from "@/components/Page.vue"
import PillCalibration from "@/components/PillCalibration.vue"
import TitleLink from "@/components/TitleLink.vue"
import { CALIBRATION_PILLS } from "@/constants"

export default {
  components: {
    Page,
    PillCalibration,
    TitleLink
  },
  computed: {
    pill() {
      return CALIBRATION_PILLS.find(
        ({ id }) => id == Number(this.$route.params.id)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.details-col {
  max-width: 400px;
  margin: 6rem auto 5rem;
}

.name {
  color: var(--color-blue-light);
  font-weight: bold;
}

.dosage {
  font-size: 1rem;
  border: 1px solid black;
  border-radius: 0.375rem;
  margin-left: 0.5rem;
  padding: 0 0.375rem;
}

.trademark {
  width: 1rem;
  height: auto;
}

.details {
  color: var(--color-gray);

  label {
    font-weight: bold;
    margin-right: 0.5rem;
  }
}

.details li {
  margin-bottom: 0.5rem;
}
</style>
