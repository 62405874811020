<template>
  <div class="wrapper">
    <Header />
    <slot name="post-header"></slot>
    <div class="content">
      <slot></slot>
    </div>
    <Footer class="mt-5" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}
</style>
